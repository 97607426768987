import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { FaStar } from "react-icons/fa"

import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Testimonials from "../components/Testimonials"
import Cards from "../components/Cards"
import { filterByLocale } from "../helpers"

const RequestBudgetButton = ({ reverseBackground }) => {
  console.log(reverseBackground)

  const classNames = reverseBackground
    ? "text-black bg-white hover:bg-gray-200"
    : "text-white bg-black hover:bg-gray-800"

  return (
    <Link
      className={
        `${classNames} text-center text-2xl w-full md:w-auto inline-block font-medium ` +
        "border-2 p-3 border-transparent   " +
        "hover:border-gray-400 hover:border-2 transition duration-150 ease-in-out font-bold " +
        "rounded-full font-semibold"
      }
      activeClassName="border-blue-600 text-gray-900 hover:border-blue-600 rounded-full"
      to="/solicitar-orcamento"
    >
      Solicitar orçamento
    </Link>
  )
}

const Hero = ({ cards, avatars }) => {
  return (
    <section className="bg-white text-gray-800 py-16 text-center">
      <div className="container mb-16">
        <h1 className="text-5xl font-bold mb-4">
          Criação de logos para{" "}
          <span className="text-purple-600">diferenciar</span> o seu negócio
        </h1>
        <p className="text-xl mb-8">
          Marca genérica, que seus clientes não sabem se é sua ou do seu
          concorrente, nunca mais
        </p>
      </div>

      <div className="mt-8">
        <div className="container">
          <div class="flex place-content-center w-16 m-auto">
            {avatars.map(imageData => {
              return (
                <div className="w-1/2 p-2">
                  <GatsbyImage
                    image={imageData.node.childImageSharp.gatsbyImageData}
                    alt={imageData.node.childImageSharp.name}
                    className="w-10 -mr-2.5"
                  />
                </div>
              )
            })}
            <div class="ml-12 flex items-center">
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
            </div>
          </div>
          <p>Tenho a confiança dos meus clientes</p>
        </div>
      </div>

      <div className="mt-8 mb-16 px-4 sm:px-0">
        <RequestBudgetButton reverseBackground={false} />
      </div>

      <>
        <div className></div>
        <Cards items={cards} />
      </>
    </section>
  )
}

const ProblemAgitation = ({ beforeAfterImage }) => {
  return (
    <section className="py-16 text-center">
      <h2 className="text-3xl font-bold mb-4">Logo genérica?</h2>
      <div className="container">
        <p className="text-lg mb-8">
          Uma marca genérica e não profissional não passa a imagem que você
          deseja para seus clientes. Seja honesto, você não tem a mesma
          confiança em um produto ou um serviço se a marca não tem qualidade.
          Aqui, você vê a diferença de uma marca com design profissional e de
          uma marca sem design profissional.
          <GatsbyImage
            image={beforeAfterImage.childImageSharp.gatsbyImageData}
            alt={beforeAfterImage.childImageSharp.name}
            className="mt-8"
          />
        </p>
      </div>
    </section>
  )
}

const Transformation = () => {
  return (
    <section className="bg-gray-100 py-16 text-center">
      <h2 className="text-3xl font-bold mb-4">Sua marca nova</h2>
      <div className="container">
        <p className="text-lg mb-8">
          Agora imagine sua empresa com uma marca nova. Uma logo profissional
          pode ter um impacto positivo na sua imagem, e consequentemente, nas
          suas vendas. Não é um gasto, é um investimento que irá se pagar
          rapidamente.
        </p>
        <p className="text-lg mb-8">
          E quem diz isso não sou apenas eu, mas também a ciência. O que
          suspeitamos desde sempre é comprovado, bom design ajuda a vender.{" "}
          <a
            href="https://journals.sagepub.com/doi/abs/10.1177/0956797612470701"
            target="_blank"
            rel="noreferrer"
            className="text-blue underline"
          >
            Nesse link
          </a>{" "}
          temos apenas um exemplo de tal estudo
        </p>
        {/* Point B of your ideal customer that they desire */}
        {/* Specific positive benefits of completing this transformation */}
      </div>
    </section>
  )
}

const SocialProof = props => {
  return (
    <section className="py-16 text-center">
      <h2 className="text-3xl font-bold mb-4">
        Alguns depoimentos de clientes que me orgulho
      </h2>
      <div className="py-8 flex justify-center">
        <Testimonials testimonials={props.testimonials} />
      </div>
    </section>
  )
}

const FinalCTA = () => {
  return (
    <section className="bg-black text-white py-16 text-center">
      <h2 className="text-4xl font-bold mb-4">
        Tá esperando o que para ser o próximo?
      </h2>
      {/* Repeat your value proposition */}
      <RequestBudgetButton reverseBackground={true} />
    </section>
  )
}

const LogoCreationLandingPage = props => {
  const currentLocale = "pt-BR"
  const testimonials = filterByLocale(props.data.testimonial, currentLocale)
  const cards = filterByLocale(props.data.portfolio, currentLocale)
  const avatars = props.data.avatars.edges
  const beforeAfterImage = props.data.beforeAfterImage

  return (
    <Layout>
      <SiteMetadata
        title="Pri Bellafronte - Designer de marcas e logos | Criação de logos"
        description={
          "Esta página apresenta meu trabalho como Designer " +
          "Gráfico para criação de logos"
        }
        image={props.data.socialCoverImage.publicURL}
      />
      <div className="font-sans">
        <Hero cards={cards.slice(0, 3)} avatars={avatars} />
        <ProblemAgitation beforeAfterImage={beforeAfterImage} />
        <Transformation />
        <SocialProof testimonials={testimonials} />
        <FinalCTA />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    testimonial: allContentfulTestimonial {
      nodes {
        company
        name
        node_locale
        title
        testimonial {
          testimonial
        }
        photo {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    portfolio: allContentfulPortfolio(
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...PortfolioCard
      }
    }
    socialCoverImage: file(
      relativePath: { eq: "bellafronte-studio-social-cover.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          height: 400
          quality: 90
          layout: CONSTRAINED
        )
      }
      publicURL
    }
    avatars: allFile(filter: { absolutePath: { regex: "/avatars/" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
    beforeAfterImage: file(relativePath: { eq: "antesdepois.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          height: 1080
          quality: 90
          layout: CONSTRAINED
        )
      }
      publicURL
    }
  }
`

export default LogoCreationLandingPage
